export default function SvgClose() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1691 13.3636L13.1682 14.3387C12.9553 14.5461 12.5976 14.5625 12.3694 14.3282L7.51471 9.34511L2.53159 14.1998C2.31863 14.4073 1.961 14.4237 1.73279 14.1894L0.757696 13.1885C0.550229 12.9756 0.533872 12.6179 0.768123 12.3897L5.77254 7.51425L0.917827 2.53113C0.71036 2.31817 0.694003 1.96054 0.928253 1.73233L1.92914 0.757232C2.14209 0.549766 2.52047 0.554705 2.72794 0.767659L7.58266 5.75079L12.5445 0.916814C12.7574 0.709348 13.1358 0.714287 13.3433 0.927242L14.3184 1.92813C14.5258 2.14108 14.5422 2.49871 14.308 2.72692L9.32483 7.58164L14.1795 12.5648C14.4078 12.799 14.4034 13.1354 14.1691 13.3636Z"
        fill="white"
      />
    </svg>
  );
}
