export default function SvgSuccess() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 26C5.83143 26 0 20.1686 0 13C0 5.83143 5.83143 0 13 0C20.1686 0 26 5.83143 26 13C26 20.1686 20.1686 26 13 26ZM13 0.720571C6.22886 0.720571 0.720571 6.22886 0.720571 13C0.720571 19.7711 6.22886 25.2794 13 25.2794C19.7711 25.2794 25.2794 19.7711 25.2794 13C25.2794 6.22886 19.7711 0.720571 13 0.720571Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.0968 16.6677C12.0068 16.6677 11.9167 16.6342 11.8471 16.5664L7.8607 12.724C7.7177 12.5857 7.71306 12.3582 7.85142 12.2152C7.98978 12.0722 8.21728 12.0675 8.36028 12.2059L12.0959 15.8069L18.8578 9.27622C19.0008 9.13787 19.2292 9.14251 19.3676 9.28551C19.5059 9.42851 19.5022 9.65694 19.3583 9.7953L12.3466 16.5674C12.277 16.6342 12.1869 16.6677 12.0968 16.6677Z"
        fill="#F3F3F3"
      />
    </svg>
  );
}
