export default function SvgAdded() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="27" rx="4" fill="black" />
      <path
        d="M19.0034 13.6432C19.0034 14.0892 18.6494 14.4541 18.2169 14.4541H14.3472V18.5243C14.3472 18.9784 13.9933 19.3432 13.5607 19.3432C13.1281 19.3432 12.7742 18.9784 12.7742 18.5243V14.4541H8.78652C8.35393 14.4541 8 14.0892 8 13.6432C8 13.1973 8.35393 12.8324 8.78652 12.8324H12.7742V8.81081C12.7742 8.36486 13.1281 8 13.5607 8C13.9933 8 14.3472 8.36486 14.3472 8.81081V12.8324H18.2169C18.6494 12.8324 19.0034 13.1973 19.0034 13.6432Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4C6.23858 4 4 6.23858 4 9V18C4 20.7614 6.23858 23 9 23H18C20.7614 23 23 20.7614 23 18V9C23 6.23858 20.7614 4 18 4H9ZM9 5C6.79086 5 5 6.79086 5 9V18C5 20.2091 6.79086 22 9 22H18C20.2091 22 22 20.2091 22 18V9C22 6.79086 20.2091 5 18 5H9Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}
