export default function SvgExet() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9.625H5.26625L12.9525 1.93875L11 0L0 11L11 22L12.9387 20.0613L5.26625 12.375H22V9.625Z"
        fill="white"
      />
    </svg>
  );
}
