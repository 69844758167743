export default function SVgContact() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 38 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_852_1207)">
        <path
          d="M36.1019 0.956055H1.77011C0.917875 0.956055 0.236084 1.64229 0.236084 2.48003V21.9709C0.236084 22.8086 0.917875 23.4948 1.77011 23.4948H19.6133L21.7484 28.2896L23.8296 23.4948H36.1019C36.9541 23.4948 37.6449 22.8086 37.6449 21.9709V2.48003C37.6449 1.64229 36.9541 0.956055 36.1019 0.956055ZM21.7484 25.2862L21.0666 23.6196L20.3848 21.9441H2.78383C2.2366 21.9441 1.79703 21.4985 1.79703 20.9638V3.4871C1.79703 2.95237 2.2366 2.51568 2.78383 2.51568H35.0882C35.6354 2.51568 36.075 2.95237 36.075 3.4871V20.9638C36.075 21.4985 35.6354 21.9441 35.0882 21.9441H23.0671"
          fill="white"
        />
      </g>
     
    </svg>
  );
}
