import { IProps } from "./Types";

export default function SvgLinkedin({ heigth = "34", width = "34" }: IProps) {
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2672 10.4994C12.2672 11.6109 11.4306 12.5002 10.0873 12.5002H10.0627C8.76926 12.5002 7.93311 11.6109 7.93311 10.4994C7.93311 9.36445 8.79473 8.5 10.1127 8.5C11.4306 8.5 12.2422 9.36445 12.2672 10.4994ZM12.0135 14.0801V25.6555H8.16092V14.0801H12.0135ZM25.9072 25.6555L25.9073 19.0186C25.9073 15.4631 24.0067 13.8083 21.4716 13.8083C19.4261 13.8083 18.5103 14.9318 17.999 15.72V14.0805H14.146C14.1967 15.1666 14.146 25.6558 14.146 25.6558H17.999V19.1912C17.999 18.8452 18.024 18.5001 18.1259 18.2524C18.4043 17.5613 19.0383 16.8457 20.1026 16.8457C21.4973 16.8457 22.0548 17.9072 22.0548 19.4627V25.6555H25.9072Z"
        fill="white"
      />
    </svg>
  );
}
