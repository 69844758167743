export default function SvgGuest() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6006 15.0014C6.6006 17.8297 6.6006 19.2448 7.58519 20.1229C8.30318 20.765 9.34153 20.938 11.0811 20.985M6.6006 7.0006C6.6006 4.17232 6.6006 2.75718 7.58519 1.87909C8.56865 1 10.1536 1 13.3213 1H14.4414C17.6091 1 19.1941 1 20.1776 1.87909C21.1622 2.75718 21.1622 4.17232 21.1622 7.0006V15.0014C21.1622 17.8297 21.1622 19.2448 20.1776 20.1229C19.3173 20.892 17.9978 20.988 15.5616 21M1 8.50075V13.5013C1 15.8585 1 17.0366 1.81993 17.7697C2.63986 18.5017 3.96048 18.5018 6.6006 18.5018M1.81993 4.23232C2.63986 3.50025 3.96048 3.50025 6.6006 3.50025"
        stroke="#F8F8F8"
        strokeWidth="1.1875"
        strokeLinecap="round"
      />
      <path
        d="M4.36035 11.001H14.4414M14.4414 11.001L11.6411 13.5012M14.4414 11.001L11.6411 8.50073"
        stroke="#F8F8F8"
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
