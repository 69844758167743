import { IProps } from "./Types";

export default function SvgMail({ width = "24", heigth = "15" }: IProps) {
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.695083 1.81045L0.69509 1.80992C0.704031 1.19565 1.39322 0.517275 2.48623 0.533184L20.7546 0.799084C21.8512 0.815044 22.5282 1.51609 22.5193 2.12757L22.3619 12.9424C22.353 13.5539 21.6558 14.235 20.5593 14.219L2.2909 13.9531C1.19451 13.9371 0.517538 13.2363 0.526257 12.6249C0.526258 12.6248 0.52626 12.6247 0.526261 12.6246L0.695083 1.81045ZM20.9241 4.35567L21.2049 4.2222L21.2094 3.91137L21.2356 2.10889L21.2473 1.30478L20.521 1.65004L11.544 5.91738L2.69499 1.39058L1.97903 1.02432L1.96733 1.82844L1.94109 3.63091L1.93657 3.94175L2.21333 4.08333L11.2819 8.72247L11.5015 8.83481L11.7243 8.72891L20.9241 4.35567Z"
        stroke="white"
      />
    </svg>
  );
}
