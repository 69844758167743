export default function SvgPhone() {
  return (
    <svg
      width="14"
      height="26"
      viewBox="0 0 14 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_50_381)">
        <path
          d="M0 0V26H14V0H0ZM5.80612 1.62703H8.31633V2.13684H5.80612V1.62703ZM7.03061 24.7851C6.7551 24.7851 6.53061 24.5465 6.53061 24.2537C6.53061 23.9608 6.7551 23.7222 7.03061 23.7222C7.30612 23.7222 7.53061 23.9608 7.53061 24.2537C7.53061 24.5465 7.30612 24.7851 7.03061 24.7851ZM12.7449 22.4531H1.53061V3.62286H12.7551V22.4531H12.7449Z"
          fill="white"
        />
        <path
          d="M10.2858 12.1484V13.8947H7.81641V16.5088H6.16335V13.8947H3.81641V12.1484H6.16335V9.52344H7.81641V12.1484H10.2858Z"
          fill="#E11F26"
        />
        <path
          d="M8.31636 1.62695H5.80615V2.13676H8.31636V1.62695Z"
          fill="#3F677E"
        />
        <path
          d="M7.03076 24.7852C7.3069 24.7852 7.53076 24.5472 7.53076 24.2537C7.53076 23.9601 7.3069 23.7222 7.03076 23.7222C6.75462 23.7222 6.53076 23.9601 6.53076 24.2537C6.53076 24.5472 6.75462 24.7852 7.03076 24.7852Z"
          fill="#46677C"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_381">
          <rect width="14" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
