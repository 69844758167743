export default function SvgSubtract() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0034 9.64324C15.0034 10.0892 14.6494 10.4541 14.2169 10.4541H10.3472V14.5243C10.3472 14.9784 9.99326 15.3432 9.56067 15.3432C9.12809 15.3432 8.77416 14.9784 8.77416 14.5243V10.4541H4.78652C4.35393 10.4541 4 10.0892 4 9.64324C4 9.1973 4.35393 8.83243 4.78652 8.83243H8.77416V4.81081C8.77416 4.36486 9.12809 4 9.56067 4C9.99326 4 10.3472 4.36486 10.3472 4.81081V8.83243H14.2169C14.6494 8.83243 15.0034 9.1973 15.0034 9.64324Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C2.23858 0 0 2.23858 0 5V14C0 16.7614 2.23858 19 5 19H14C16.7614 19 19 16.7614 19 14V5C19 2.23858 16.7614 0 14 0H5ZM5 1C2.79086 1 1 2.79086 1 5V14C1 16.2091 2.79086 18 5 18H14C16.2091 18 18 16.2091 18 14V5C18 2.79086 16.2091 1 14 1H5Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}
