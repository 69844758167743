export default function SvgSaveContact() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 4.4375C8.605 4.4375 9.5 3.58445 9.5 2.53125C9.5 1.47805 8.605 0.625 7.5 0.625C6.395 0.625 5.5 1.47805 5.5 2.53125C5.5 3.58445 6.395 4.4375 7.5 4.4375ZM3 3.48438V2.05469H2V3.48438H0.5V4.4375H2V5.86719H3V4.4375H4.5V3.48438H3ZM7.5 5.39062C6.165 5.39062 3.5 6.02922 3.5 7.29688V8.25H11.5V7.29688C11.5 6.02922 8.835 5.39062 7.5 5.39062Z"
        fill="black"
      />
    </svg>
  );
}
