export default function SvgEye() {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17993 9.52259C4.20342 9.52259 0.254289 5.17785 0.0888479 4.99249C-0.029616 4.86111 -0.029616 4.66149 0.0888479 4.53011C0.254289 4.34475 4.20342 0 9.17993 0C14.1564 0 18.1056 4.34475 18.271 4.53011C18.3895 4.66149 18.3895 4.86111 18.271 4.99249C18.1056 5.17785 14.1564 9.52259 9.17993 9.52259ZM0.830268 4.7613C1.71875 5.6555 5.15522 8.828 9.17993 8.828C13.2046 8.828 16.6411 5.6555 17.5296 4.7613C16.6411 3.86709 13.2046 0.694589 9.17993 0.694589C5.15522 0.694589 1.71875 3.86709 0.830268 4.7613Z"
        fill="white"
      />
      <path
        d="M9.17985 1.26807C7.24563 1.26807 5.677 2.83242 5.677 4.76138C5.677 6.69034 7.24563 8.2547 9.17985 8.2547C11.1141 8.2547 12.6827 6.69034 12.6827 4.76138C12.6827 2.83242 11.1141 1.26807 9.17985 1.26807ZM7.97888 4.76138C7.42536 4.76138 6.97704 4.31428 6.97704 3.76227C6.97704 3.21027 7.42536 2.76316 7.97888 2.76316C8.53239 2.76316 8.98071 3.21027 8.98071 3.76227C8.98071 4.31428 8.53239 4.76138 7.97888 4.76138Z"
        fill="white"
      />
    </svg>
  );
}
