import { IProps } from "./Types";

export default function SvgPhoneCall({ width = "22", heigth = "20" }: IProps) {
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9832 19.171L13.9757 19.1686L13.9682 19.1664C9.33253 17.7929 5.80668 14.9045 2.95199 10.9435C1.87782 9.45084 0.91476 7.93306 0.623794 6.16672L0.622473 6.1587L0.620892 6.15072C0.218133 4.11975 0.828868 2.34409 2.34561 0.952755L2.34567 0.952825L2.35271 0.94611C2.48016 0.824551 2.77531 0.682751 3.18505 0.590147C3.58034 0.50081 3.99409 0.47729 4.2961 0.522183C4.42329 0.541091 4.56917 0.630241 4.6771 0.778129C4.76357 0.896621 4.82896 1.02959 4.89891 1.2043C5.22169 2.01053 5.56141 2.80359 5.89712 3.5873C6.13353 4.13921 6.36796 4.68647 6.59308 5.23042C6.63838 5.40232 6.62884 5.55326 6.53879 5.72667L6.53869 5.72661L6.53388 5.73638C6.25477 6.30374 5.82798 6.79161 5.35793 7.32894L5.34415 7.34469C5.08789 7.58522 4.91949 7.87944 4.89212 8.22067C4.86504 8.55821 4.98211 8.86738 5.15153 9.1292C6.90955 12.1176 9.29491 14.272 12.4981 15.5305C12.7776 15.6522 13.0834 15.7168 13.3939 15.6491C13.7092 15.5805 13.9564 15.3916 14.1462 15.1457C14.6948 14.5172 15.2228 13.8242 15.7173 13.1598L15.7173 13.1599L15.7214 13.1542C15.8158 13.0236 15.8972 12.9833 15.957 12.9689C16.0224 12.9532 16.1247 12.9539 16.2791 13.0125C16.4585 13.0985 16.6379 13.1845 16.817 13.2704C17.6459 13.6678 18.4694 14.0627 19.2701 14.4345C19.3324 14.4642 19.3964 14.4962 19.4619 14.529C19.626 14.611 19.7999 14.6979 19.9808 14.766C20.226 14.8909 20.3825 14.9763 20.4901 15.0503C20.5952 15.1227 20.6145 15.1585 20.6194 15.1682C20.6255 15.1803 20.6433 15.2213 20.6429 15.3572C20.6424 15.4991 20.6239 15.6855 20.5935 15.9745L20.5924 15.9854L20.5917 15.9964C20.4767 17.8808 19.0732 18.8914 17.2536 19.4181C17.0071 19.4738 16.7953 19.4978 16.5943 19.4999C16.2043 19.5039 15.8084 19.4874 15.4063 19.4645C14.9385 19.4379 14.4586 19.3282 13.9832 19.171Z"
        stroke="white"
      />
    </svg>
  );
}
